:root {
	color-scheme: light dark;
	--color-brand-hue: .7turn;
	--color-surface-hsl: var(--color-brand-hue) 0% 100%;
	--color-surface: hsl(var(--color-surface-hsl));
	--color-surface-container: hsl(var(--color-brand-hue) 50% 98%);
	--color-on-surface-hsl: var(--color-brand-hue) 0% 0%;
	--color-on-surface-variant-hsl: var(--color-brand-hue) 0% 20%;
	--color-on-surface-variant: hsl(var(--color-on-surface-variant-hsl));
	--color-outline-variant: hsl(var(--color-brand-hue) 0% 92%);
	--hover-state-layer-opacity: .08;
}

@media (prefers-color-scheme: dark) {
	:root {
		--color-surface-hsl: var(--color-brand-hue) 9% 15%;
		--color-surface-container: hsl(var(--color-brand-hue) 11% 12%);
		--color-on-surface-hsl: var(--color-brand-hue) 0% 100%;
		--color-on-surface-variant-hsl: var(--color-brand-hue) 2% 83%;
		--color-outline-variant: hsl(var(--color-brand-hue) 3% 36%);
	}
}

html {
	font-family: system-ui;
	line-height: 1.5;
}

body {
	display: flex;
	flex-direction: column;
	margin: 0;
	block-size: 100vb;
	background: var(--color-surface-container);
}

.tile {
	background: var(--color-surface);
	--border-radius: 1rem;
	border-start-start-radius: var(--border-radius);
	border-start-end-radius: var(--border-radius);
}

.dropzone {
	position: relative;
	outline: 2px solid HighlightText;
}

.dropzone::after {
	content: '';
	position: absolute;
	top: 0;
	z-index: 1;
	border-radius: inherit;
	opacity: .3;
	block-size: 100%;
	inline-size: 100%;
	background: Highlight;
}

body > aside {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: visible;
	container-type: inline-size;
	min-inline-size: 16rem;
	padding: 1rem;
	padding-inline-end: .5rem;
}

body > aside > details {
	display: inline-block;
	inline-size: 100%;
	overflow-block: auto;
}

body > aside > details > summary {
	display: flex;
	align-items: center;
	justify-content: center;
	inline-size: 2.5rem;
	transition: margin 150ms ease-out;
	list-style-type: none;
	aspect-ratio: 1;
	border-radius: 50%;
}

body > aside > details[open] > summary {
	margin-bottom: 10px;
}

body > aside > section {
	display: flex;
	position: absolute;
	top: .75rem;
	right: 0;
	left: 3.25rem;
	align-items: center;
	padding-block: .5rem;
	padding-inline: 1rem;
}

.elevate {
	border-block-end: 2px solid var(--color-outline-variant);
}

body > aside > section > h4 {
	margin-block: 0;
	flex: 1;
}

#profile-dialog > div > button,
body > aside > section > button:first-of-type {
	font-weight: bold;
	border: none;
	padding-inline: .75rem;
	padding-block: .5rem;
	border-radius: .5rem;
	background: Highlight;
	box-shadow: 0 0 4px 0 hsl(var(--color-brand-hue) 0% 100% / 0.1);
}

#profile-dialog > div > button:hover,
body > aside > section > button:first-of-type:hover {
	box-shadow: 0 0 8px 0 hsl(var(--color-brand-hue) 0% 100% / 0.25);
}

#profile-container {
	position: relative;
	direction: rtl;
}

#profile-button {
	block-size: 2rem;
	aspect-ratio: 1;
	background: linear-gradient(0deg, hsl(181 70% 45%) 0%, hsl(41 98% 58%) 100%);
	font-weight: bold;
	border: none;
	border-radius: 50%;
}

#profile-dialog {
	inset-inline-end: -1rem;
	inline-size: calc(100cqi + .25rem);
	direction: ltr;
	inset-inline-start: 2rem;
	margin-block: 1rem;
}

#profile-dialog > div {
	flex-direction: column;
	display: flex;
	align-items: flex-start;
}

#profile-dialog > div > h4 {
	margin-block-start: 0;
}

#profile-dialog > div > label {
	display: flex;
	inline-size: 100%;
}

#profile-dialog > div > label > select {
	flex: 1;
	margin-inline-start: .5rem;
}

#profile-dialog > div > button {
	margin-block-start: 2rem;
	align-self: flex-end;
	border: 2px solid indianred;
	box-shadow: unset;
	background: none;
}

#profile-dialog > div > button:hover {
	background: indianred;
}

body > aside > details > nav > h3 {
	padding-inline: 1rem;
}

body > aside > details > nav > ul {
	/* Reset */
	list-style-type: none;
	margin-block: 0;
	padding-inline-start: 0;
}

body > aside > details > nav > ul > li > details > summary:first-of-type {
	list-style-type: none;
}

body > aside > details > nav > ul > li > details > ul {
	/* Reset */
	list-style-type: none;
	padding-inline-start: 0;
}

body > aside > details > nav ul > li > button {
	/* Reset */
	border: none;
	background: none;

	padding-inline: 0;
	inline-size: 100%;
	border-radius: inherit;
}

body > aside > details > nav > ul > li > details > summary,
body > aside > details > nav ul > li {
	border-radius: .5rem;
}

body > aside > details > nav > ul > li > details > ul > li > button {
	padding-inline-start: 1.25rem;
}

body > aside > details > summary:hover,
body > aside > details > nav > ul > li > details > summary:hover,
body > aside > details > nav ul > li > button:hover,
body > main > ol > li > button:hover,
body > main > div > menu[role="toolbar"] > li > *:hover {
	background: hsl(var(--color-on-surface-hsl) / var(--hover-state-layer-opacity));
}

body > main {
	display: flex;
	flex: 4;
	overflow-block: auto;
	box-shadow: 0 0 .8rem 0 hsl(var(--color-brand-hue) 0% 44% / 0.14);
}

@media screen and (prefers-color-scheme: dark) {
	body > main {
		box-shadow: none;
	}
}

body > main > ol {
	/* Reset */
	list-style-type: none;
	margin-block: 0;
	padding-inline-start: 0;

	flex: 1 24rem;
	min-inline-size: 16rem;
	padding: .5rem;
	overflow-block: auto;
}

body > main > ol > li > button {
	/* Reset */
	padding: 0;
	border: none;
	font: inherit;
	background: none;
	text-align: start;

	inline-size: 100%;
	border-radius: .5rem;
}

body > main > ol > li > button > article {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 1rem;
}

body > main > ol > li > button > article > header {
	display: flex;
	position: relative;
	inline-size: 100%;
}

body > main > ol > li > button > article > header > h4 {
	margin-block: 0;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
}

body > main > ol > li > button > article > header > input[type="checkbox"] {
	--size: .75rem;
	--box-shadow-spacing: 1rem;
	appearance: initial;
	block-size: calc(var(--size) + var(--box-shadow-spacing));
	aspect-ratio: 1;
	border-radius: 50%;
	margin-block-start: -.1rem;
	margin-inline-start: .25rem;
	margin-inline-end: -.5rem;
}

body > main > ol > li > button > article > header > input[type="checkbox"]:before {
	content: "";
	position: absolute;
	block-size: var(--size);
	aspect-ratio: 1;
	margin-inline-start: calc(var(--box-shadow-spacing) / 2);
	margin-block-start: calc(var(--box-shadow-spacing) / 2);
	border-radius: 50%;
	background: Highlight;
	cursor: pointer;
	appearance: initial;
}

body > main > ol > li > button > article > header > input[type="checkbox"]:hover {
	background: hsl(var(--color-on-surface-variant-hsl) / var(--hover-state-layer-opacity));
}

body > main > ol > li > button > article > header > input[type="checkbox"]:hover:before {
	content: "";
}

body > main > ol > li > button > article > header > input[type="checkbox"]:checked:before {
	background: unset;
	border: 2px solid var(--color-on-surface-variant);
}

body > main > ol > li > button > article > p {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-block: .5rem;
	inline-size: 100%;
	font-size: .9rem;
	color: var(--color-on-surface-variant);
}

body > main > ol > li > button > article > address {
	display: inline-block;
	flex: 1;
	font-size: .8rem;
	font-style: unset;
	font-weight: bold;
	color: var(--color-on-surface-variant);
}

body > main > ol > li > button > article > time {
	flex: 1;
	text-align: end;
	font-size: .8rem;
	font-weight: bold;
	color: var(--color-on-surface-variant);
}

body > main > hr {
	display: none;
	width: 2px;
	margin-block: 1rem;
	background-color: var(--color-outline-variant);
	border: none;
	border-radius: 1rem;
}

body > main > div {
	display: none;
	--max-content-block-size: 45rem;
	flex: 1 var(--max-content-block-size);
	overflow-block: auto;
	padding-block-end: 1rem;
}

body > main > div > span {
	display: flex;
	block-size: 100%;
	justify-content: center;
	align-items: center;
	font-size: 1.17rem;
	font-weight: bold;
	margin-inline: 1.5rem;
}

body > main > div > menu[role="toolbar"] {
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: end;
	block-size: 4.5rem;
	background: var(--color-surface);
	padding-inline: 1rem;
	list-style-type: none;
}

body > main > div > menu[role="toolbar"] > li > * {
	display: flex;
	align-items: center;
	justify-content: center;
	inline-size: 2.5rem;
	aspect-ratio: 1;
	border: none;
	border-radius: 50%;
	background: none;
}

body > main > div > menu[role="toolbar"] > li > a {
	text-decoration: none;
	color: unset;
}

body > main > div > menu[role="toolbar"] > li:first-child {
	margin-inline-end: auto;
}

body > main > div > article {
	--padding-inline: 1.5rem;
	max-inline-size: calc(var(--max-content-block-size) + var(--padding-inline) * 2);
	margin-inline: auto;
	padding-inline: var(--padding-inline);
}

body > main > div > article > slot[name="media"] > iframe {
	display: block;
	block-size: auto;
	inline-size: 100%;
	margin-block: 2rem;
	margin-inline: auto;
	border: 0;
	aspect-ratio: 640 / 390;
}

body > main > div > article > slot[name="content"] > blockquote {
	border-inline-start: 4px solid var(--color-outline-variant);
	margin-block: 1.5rem;
	margin-inline: 0;
	padding-block: .5rem;
	padding-inline: 1rem;
}

body > main > div > article > slot[name="content"] > blockquote p {
	margin-block-start: 0;
}

body > main > div > article > slot[name="content"] pre {
	background: hsl(var(--color-brand-hue) 11% 12% / 0.05);
	padding: 1rem;
	border-radius: 1rem;
	overflow-x: scroll;
}

body > main > div > article > slot[name="content"] :is(img, iframe, video) {
	max-inline-size: 100%;
}

body > main > div > article > slot[name="content"] img {
	block-size: auto;
}

@media screen and (prefers-color-scheme: dark) {
	body > main > div > article > slot[name="content"] pre {
		background: hsl(var(--color-brand-hue) 0% 0% / 0.2);
	}
}

/* TODO Replace selected class with aria roles. */
/* TODO https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role the keyboard interactions look dope as shit. */
[aria-selected="true"],
.selected {
	background: Highlight;
}

/*
TODO Firefox doesn't support :has().
 https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
 The gray overlay is a "nice-to-have" feature, not a necessity.
*/
body:has(> aside > details[open]) > main {
	pointer-events: none;
	opacity: .5;
}

@media screen and (width >= 50rem) {
	body > main > ol {
		display: initial;
		max-inline-size: 24rem;
	}

	body > main > hr,
	body > main > div {
		display: initial;
	}

	body > main > div > menu[role="toolbar"] > li > button {
		display: none;
	}
}

@media screen and (width >= 70rem) {
	body {
		flex-direction: row;
		padding-block: 1rem;
	}

	.tile {
		border-radius: var(--border-radius);
	}

	body > aside {
		flex: 1;
		max-inline-size: 20rem;
		padding-block: unset;
		padding-inline: .5rem;
	}

	body > aside > details > summary {
		display: none;
	}

	body > aside > section {
		order: -1;
		position: unset;
	}

	body > main {
		margin-inline-end: 1rem;
		box-shadow: unset;
	}

	body:has(> aside > details[open]) > main {
		pointer-events: unset;
		opacity: 1;
	}
}
